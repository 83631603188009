// @ts-nocheck

import _ from "lodash";
import PropTypes from "prop-types";
import React, { Component, PureComponent, Fragment } from "react";

// import { connect } from "react-redux";

import isEqual from "react-fast-compare";
import { Link } from "react-router-dom";
import Dimmer from "semantic-ui-react/dist/commonjs/modules/Dimmer";
import { replace } from "connected-react-router";

import ThirdParty from "core/ThirdParty";
import { Row, Col, Container } from "kit/grid";
import { Icon, Button } from "kit/ui";
import QueryString from "core/QueryString";

import checkRouteAccessWithCustomerStatus from "../helpers/checkRouteAccessWithCustomerStatus";

import AuthenticationError from "../components/AuthenticationError/AuthenticationError.component";

import routesObject from "../routesObject";

import ReduxDispatcherEvent from "./ReduxDispatcherEvent";



class RouteAuthentication extends Component {

  render() {
    const {
      activeRoute = {},
      children,
      initInProgress,
      sendErrorMessage
    } = this.props;

    const {
      customerStatus
    } = this.context;

    const {
      accessGranted,
      redirectTo
    } = checkRouteAccessWithCustomerStatus(activeRoute, customerStatus);

    if (redirectTo === routesObject.loanRequest.url) {
      ReduxDispatcherEvent.emit(replace(redirectTo));
    }

    if (!activeRoute || initInProgress) {
      return <div />;
    }
    else if (!accessGranted) {
      return <AuthenticationError sendErrorMessage={sendErrorMessage} customerStatus={customerStatus} activeRoute={activeRoute} />;
    }
    else if (accessGranted) {
      return children;
    }

  }
}

RouteAuthentication.contextTypes = {
  customerStatus: PropTypes.string
};

RouteAuthentication.propTypes = {
  initInProgress: PropTypes.bool.isRequired,
  activeRoute: PropTypes.object,
  sendErrorMessage: PropTypes.func,
  children: PropTypes.any
};

// const mapDispatchToProps = (dispatch) => {
//   return {
//     handleLogout: () => dispatch(authUserLogout())
//   };
// };

export default RouteAuthentication;
// export default connect(null, mapDispatchToProps)(RouteAuthentication);
