// @ts-nocheck
import React, { Component, PureComponent, Fragment } from "react";
import _ from "lodash";

import { Global, css } from "@emotion/react";
import type { InjectedFormProps } from "redux-form/dist/redux-form";

import { Modal } from "semantic-ui-react";

import ModalProvider from "core/ModalProvider.tsx";

import UpdatePasswordForm from "./UpdatePassword.form";

import * as styles from "./UpdatePasswordModal.styles.js";

import vpnKey24px from "./assets/vpn_key-24px.svg";



type ComponentPropTypes = {}


class UpdatePasswordModal extends PureComponent<InjectedFormProps & ComponentPropTypes> {

  static MODAL = UpdatePasswordForm.MODAL;

  render() {
    return (
      <>
        <Global styles={styles.modalStyles} />
        <ModalProvider id={UpdatePasswordModal.MODAL}>
          <Modal
            id="update-password-modal"
            closeOnDimmerClick={false}
            closeOnEscape={false}
            closeOnDocumentClick={false}
            size="tiny"
            closeIcon={null}>
            <Modal.Content>
              <div css={styles.iconHolder}>
                <img width="32" height="32" src={vpnKey24px}
                  alt="Key Icon" />
              </div>
              <p css={styles.title}>¿Cambiar Contraseña?</p>
              <p css={styles.desc}>Introduce tu nueva contraseña y confírmala en el segundo campo.</p>
              <UpdatePasswordForm />
            </Modal.Content>
          </Modal>
        </ModalProvider>
      </>
    );
  }
}


export default UpdatePasswordModal;
