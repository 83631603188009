import { css, keyframes } from "@emotion/react";



const MYK_PROGRESS_HEIGHT = "1px";
const MYK_PROGRESS_BAR_COLOR = "#4fbeff";
const MYK_PROGRESS_DURATION = "1.5s";
const MYK_PROGRESS_TRANSITION_SPEED = ".2s";


const indeterminate1 = keyframes`
0% {
  left:0%;
  width:0%
}
50% {
  left:25%;
  width:75%
}
75% {
  left:100%;
  width:0%
}
`;
const indeterminate2 = keyframes`
0%,50% {
  left:0%;
  width:0%
}
75%{
  left:0%;
  width:55%
}
100%{
  left:100%;
  width:20%
}
`;


export const base = css`
  display: block;
  position: fixed;
  width: 100%;
  height: ${MYK_PROGRESS_HEIGHT};
  top: 0px;
  left: 0;
  right: 0;
  z-index: 500;


  > div {
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    transition: width ${MYK_PROGRESS_TRANSITION_SPEED} cubic-bezier(.4,0,.2,1);;
    left: 0;
    z-index: 1;
    height: ${MYK_PROGRESS_HEIGHT};
    background-color: ${MYK_PROGRESS_BAR_COLOR};
    animation-duration: ${MYK_PROGRESS_DURATION};
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    &:nth-of-type(1) {
      animation-name: ${indeterminate1};
    }
    &:nth-of-type(2) {
      animation-name: ${indeterminate2};
    }
  }
`;
