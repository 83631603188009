// @ts-nocheck
import _ from "lodash";

import { RouteObjectType } from "@myk";

class RoutesMappedClass {

  routesMapped = [];

  init(routesObject: Object): RouteObjectType[] {
    this.routesMapped = _.flow(
      obj => _.mapValues(
        obj,
        (val: RouteObjectType, key: string) => ({
          key: _.defaultTo(val.key, key),
          home: false,
          authTypes: [],
          nav: [],
          titles: {},
          meta: {},
          ...routesObject[key],
          slug: _.kebabCase(key) || ""
        })
      ),
      _.values
    )(routesObject);
  }

  returnRoutes(): RouteObjectType[] {
    return this.routesMapped;
  }

}


const RoutesInstance = new RoutesMappedClass();

export default RoutesInstance;
