import { css } from "@emotion/react";

import {
  BREAKPOINTS,
  COLORS,
  FLEX,
  HELPERS,
  SPACES,
  MIN_HEIGHT,
  VARS,
  STYLES
} from "styles-js";

export const base = css`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;
`;

export const wrapper = css`
  background-color: white;
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
  flex: 1 1 auto;
  transition: all 200ms ease-out;
  transform: translate(0, 0);
  border-bottom: 1px solid ${COLORS.paleGrey};
`;

export const hidden = css`
  transform: translate(0, -${VARS.headerHeight}px);
`;

export const inner = css`
  height: ${VARS.headerHeight}px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  ${HELPERS.media.largerThan.custom(VARS.mobileBreakpoint)} {
    align-items: center;
    justify-content: flex-start;
  }
  ${HELPERS.media.smallerThan.custom(VARS.mobileBreakpoint)} {
  }
`;

export const logoHolder = css`
  display: flex;
  align-items: center;
  ${HELPERS.media.smallerThan.custom(VARS.mobileBreakpoint)} {
    flex: 1 1 auto;
  }
`;

export const logo = css`
  width: 120px;
  height: auto;
`;

//
// ─── LOGIN BTN ──────────────────────────────────────────────────────────────────
//
export const loginBtn = css`
  height: 40px;
  width: auto;
  min-width: 60px;
  max-width: 200px;
  padding: 0 20px;
  font-size: 12px;
  text-align: center;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${COLORS.marineBlue};
  border: 1px solid ${COLORS.marineBlue};
  white-space: nowrap;
  svg {
    margin-right: 3px;
  }
  &:hover {
    text-decoration: none;
    background-color: ${COLORS.marineBlue};
    > span.txt {
      color: white;
    }
    svg path {
      fill: white;
    }
  }
  ${HELPERS.media.largerThan.custom(VARS.mobileBreakpoint)} {
    width: auto;
  }
  ${HELPERS.media.smallerThan.custom(VARS.mobileBreakpoint)} {
    min-width: auto;
    height: 36px;
    padding: 0 10px;
    border-color: transparent;
    span.txt {
      display: none !important;
    }
  }
  ${HELPERS.media.largerThan.custom(VARS.mobileBreakpoint)} {
    span.txt.short {
      display: none !important;
    }
    span.txt.full {
      text-overflow: ellipsis;
      overflow: hidden;
      flex: 1 1 auto;
      white-space: nowrap;
    }
  }
`;
export const loadingStyles = css`
  &,
  span,
  svg {
    padding: 0;
    margin: 0;
    width: 20px;
    height: 20px;
  }
`;
export const userLoggedInName = css`
  font-size: 13px;
  font-weight: 800;
  ${HELPERS.media.smallerThan.custom(VARS.mobileBreakpoint)} {
    border-color: ${COLORS.marineBlue};
    span.txt.short {
      display: block !important;
      text-transform: uppercase;
    }
  }
`;

//
// ─── NAV ────────────────────────────────────────────────────────────────────────
//
export const navHolder = css`
  margin: 0;
  padding: 0;
  flex: 1 1 auto;
  padding: 0 10px;
  ${HELPERS.media.smallerThan.custom(VARS.mobileBreakpoint)} {
    display: none !important;
  }
  ul {
    display: flex;
    align-items: center;
    justify-content: space-around;
    list-style: none;
    padding-inline-start: 0;
    ${HELPERS.media.smallerThan.lg} {
      justify-content: center;
      gap: 16px;
    }
    li {
      width: max-content;
      ${HELPERS.media.smallerThan.lg} {
        width: min-content;
        flex: 1 1 auto;
      }
      .accountLink {
        font-weight: 800;
      }
      a {
        display: block;
        font-size: 14px;
        font-weight: 600;
        line-height: 1.36;
        letter-spacing: normal;
        text-align: center;
        color: ${COLORS.charcoalGrey};
        ${HELPERS.media.smallerThan.md} {
          font-size: 14px;
          padding: 3px 5px;
        }
        &:hover {
          color: ${COLORS.marineBlue};
          color: black;
          text-decoration: underline;
        }
      }
    }
  }
`;

export const logoutBtn = css`
  width: 30px;
  height: 30px;
  padding: 0;
  margin: 0;
  margin-left: 10px;
  -webkit-appearance: none;
  background: none;
  color: ${COLORS.alizarin};
  border: none;
  cursor: pointer;
  border: 1px solid transparent;
  ${HELPERS.media.smallerThan.custom(VARS.mobileBreakpoint)} {
    display: none;
  }
  svg {
  }
  &:hover {
    border-color: ${COLORS.alizarin};
  }
`;

//
// ─── MOBILE MENU ────────────────────────────────────────────────────────────────
//
export const mobileTogglerHolder = css`
  display: none;
  ${HELPERS.media.smallerThan.custom(VARS.mobileBreakpoint)} {
    display: block;
    margin-left: 3px;
  }
`;
export const toggler = css`
  width: 44px;
  height: 44px;
  width: 38px;
  height: 38px;
  padding: 0;
  display: inline-block;
  cursor: pointer;
  transition-property: opacity, filter;
  transition-duration: 0.15s;
  transition-timing-function: linear;
  font: inherit;
  color: inherit;
  text-transform: none;
  background-color: transparent;
  border: 1px solid transparent;
  margin: 0;
  outline: 0;
  overflow: visible;
  text-align: center;
  &:hover {
    outline: 0;
    /* border-color: ${COLORS.marineBlue}; */
    box-shadow: inset 0 0 0 1px ${COLORS.marineBlue};
  }
`;
export const mobileMenuActive = css`
  > span > span {
    transform: rotate(45deg);
    transition-delay: 0.12s;
    transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    &::before {
      top: 0;
      opacity: 0;
      transition: top 0.075s ease, opacity 0.075s 0.12s ease;
    }
    &::after {
      bottom: 0;
      transform: rotate(-90deg);
      transition: bottom 0.075s ease,
        transform 0.075s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1);
    }
  }
`;
export const togglerBox = css`
  width: 24px;
  height: 24px;
  display: inline-block;
  position: relative;
`;
export const togglerInner = css`
  display: block;
  top: 50%;
  transition-duration: 0.075s;
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  &,
  &::before,
  &::after {
    content: "";
    display: block;
    width: 100%;
    height: 2px;
    background-color: #000;
    position: absolute;
    transition-property: transform;
    transition-duration: 0.15s;
    transition-timing-function: ease;
  }
  &::before {
    top: -5px;
    transition: top 0.075s 0.12s ease, opacity 0.075s ease;
  }
  &::after {
    bottom: -5px;
    transition: bottom 0.075s 0.12s ease,
      transform 0.075s cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }
`;
