import { css } from "@emotion/react";

import { BREAKPOINTS, COLORS, FLEX, HELPERS, SPACES, MIN_HEIGHT, VARS, STYLES } from "styles-js";


const SIZES = {
  small: 300,
  medium: 400,
  large: 500
};


export const globalStyles = css`
  .ui.dimmer {
     background-color: rgba(0,0,0,.35);
  }
`;

export const base = css`
background-color: white;
padding: 0;
overflow: hidden;
max-width: ${SIZES.medium}px;
box-shadow: 0 4px 23px 2px rgba(0,0,0,.43);
&.ui.modal > .content {
  padding: 10px;
}
`;



export const sizes = {
  small: css`
  max-width: ${SIZES.small}px;
  `,
  large: css`
  max-width: ${SIZES.large}px;
  `
};



export const subTypes = {
  success: css`
  /* background-color: ${HELPERS.color.HexToRgba(COLORS.greenishTeal, 0.05)}; */
  .confirm-title {
    color: ${COLORS.greenishTeal};
  }
  .confirm-icon svg path {
    fill: ${COLORS.greenishTeal};
  }
  `,
  warning: css`
  /* background-color: ${HELPERS.color.HexToRgba(COLORS.orangeYellow, 0.05)}; */
  .confirm-title {
    color: ${COLORS.orangeYellow};
  }
  .confirm-icon svg path {
    fill: ${COLORS.orangeYellow};
  }
  `,
  info: css`
  /* background-color: ${HELPERS.color.HexToRgba(COLORS.orangeYellow, 0.05)}; */
  .confirm-title {
    color: ${COLORS.azure};
  }
  .confirm-icon svg path {
    fill: ${COLORS.azure};
  }
  `,
  error: css`
  /* background-color: ${HELPERS.color.HexToRgba(COLORS.coral, 0.05)}; */
  .confirm-title {
    color: ${COLORS.coral};
  }
  .confirm-icon svg path {
    fill: ${COLORS.coral};
  }
  `
};



export const icon = css`
  margin-top: 10px;
  svg {
    width: 32px;
    height: 32px;
    display: block;
    margin: 0 auto;
  }
`;



export const title = css`
  text-align: center;
  display: flex;
  justify-content: center;
  font-size: 24px;
  font-weight: 800;
  line-height: 1.65;
  text-align: center;
  color: ${COLORS.charcoalGrey};
  margin: 10px 10px 20px;
  padding: 0 10px;
`;


export const content = css`
  text-align: center;
  font-weight: ${VARS.font.semiBold};
  display: flex;
  justify-content: center;
  color: ${COLORS.charcoalGrey};
  font-size: 16px;
  line-height: 1.5;
  margin-bottom: 10px;
`;


export const description = css`
  display: flex;
  font-size: 12px;
  line-height: 1.5;
  justify-content: center;
  margin-bottom: 20px;
  padding: 10px;
  font-weight: ${VARS.font.regular};
  text-align: center;
  color: ${COLORS.charcoalGrey};

`;



export const buttonNo = css`
  ${HELPERS.media.extraNarrow}{
    font-size: 14px;
  }
  &:hover {
  }
`;
export const buttonYes = css`
  ${HELPERS.media.extraNarrow}{
    font-size: 14px;
  }
`;

export const actions = css`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 25px -8px 0 ;
  padding: 1px 3px;
  & button,
  a {
    margin: 0 8px;
  }
  span {
    width: max-content
  }
`;

export const closeIcon = css`
  position: absolute;
  top: 1rem;
  right: 1rem;
  cursor: pointer;
  opacity: 1 !important;
  &:hover {
    background-color: rgba(0, 0, 0, 0.04);
    transform: scale(1.2);
  }
`;
