// @ts-nocheck
import React, { lazy, Suspense } from "react";


const LazyComponent = lazy(() =>
  import(/* webpackChunkName: "footer" */"./Footer.component")
);


const Footer = props =>
  <Suspense
    fallback={<div />}>
    <LazyComponent {...props} />
  </Suspense>;

export default Footer;
