// @ts-nocheck
import PropTypes from "prop-types";
import React, { Component, PureComponent, Fragment } from "react";
import _ from "lodash";

import { Global } from "@emotion/react";

import * as styles from "./App.styles";

class GlobalStyles extends Component {
  shouldComponentUpdate = _.stubFalse;
  render() {
    return (
      <>
        <Global styles={styles.fonts} />
        {/* <Global styles={styles.fontsAlt} /> */}
        <Global styles={styles.reset} />
        <Global styles={styles.site} />
        <Global styles={styles.base} />
      </>
    );
  }
}


export default GlobalStyles;
