// @ts-nocheck
import _ from "lodash";

import store from "../store/configureStore";

const ACTION_TYPE = "#app/ERROR_LOG/CATCH";


const ErrorLoggerService = (category = "APP", ...rest) => {

  const action = {
    type: ACTION_TYPE,
    payload: [
      "ErrorLoggerService: ",
      category,
      ...rest
    ].join()
  };
  store.dispatch(action);

  window["console"]["error"](category, ...rest); // eslint-disable-line dot-notation

};

window.ErrorLoggerService = ErrorLoggerService;

export default ErrorLoggerService;
