// @ts-nocheck
import _ from "lodash";
import PropTypes from "prop-types";
import React, { Component, PureComponent, Fragment } from "react";

import { Provider } from "react-redux";

import { bindActionCreators } from "@reduxjs/toolkit";
import { ConnectedRouter } from "connected-react-router";

import { alertActions } from "kit/alert";
import ReduxDispatcherEvent from "core/ReduxDispatcherEvent";


import appConfig from "./config/appConfig";
import rootSaga from "./sagas/rootSaga";
import history from "./utils/history";
import store from "./store/configureStore";

import GlobalStyles from "./components/GlobalStyles/GlobalStyles.component";

import { confirmActions } from "./containers/Confirm/Confirm.slice";

import Routes from "./Routes";
import AppErrorBoundary from "./App.ErrorBoundary";
import AppGlobalStyles from "./App.GlobalStyles";


store.runSaga(rootSaga);


class App extends Component {

  constructor(props, context) {
    super(props, context);
    appConfig(store.dispatch);
    ReduxDispatcherEvent.setDispatchFunction(store.dispatch);
  }

  getChildContext() {
    return {
      confirm: bindActionCreators(confirmActions, store.dispatch),
      alert: bindActionCreators(alertActions, store.dispatch),
      store,
      mobile: (/Mobile/gi).test(window.navigator.userAgent)
    };
  }

  componentDidCatch(err, info) {
    // window["console"]["error"]("componentDidCatch err ", err); // eslint-disable-line
    ErrorLoggerService("componentDidCatch err ", err); // eslint-disable-line
    // window["console"]["error"]("componentDidCatch info ", info); // eslint-disable-line
    ErrorLoggerService("componentDidCatch info ", info); // eslint-disable-line
  }

  render() {
    return (
      <>
        <AppGlobalStyles />
        <GlobalStyles />
        <Provider store={store}>
          <AppErrorBoundary>
            <ConnectedRouter history={history}>
              <Routes />
            </ConnectedRouter>
          </AppErrorBoundary>
        </Provider>
      </>
    );
  }
}


App.childContextTypes = {
  confirm: PropTypes.object.isRequired,
  alert: PropTypes.object.isRequired,
  store: PropTypes.object.isRequired,
  mobile: PropTypes.bool.isRequired
};

export default App;
