// @ts-nocheck
import React, { Component, PureComponent, Fragment } from "react";
import PropTypes from "prop-types";
import _ from "lodash";

import { connect } from "react-redux";

import * as styles from "./RequestsInProgressBar.styles.js";


class RequestsInProgressBar extends Component {

  constructor(props, context) {
    super(props, context);
  }

  // UNSAFE_componentWillMount() {}
  // componentDidMount() {}
  // UNSAFE_componentWillReceiveProps(nextProps) {}
  // shouldComponentUpdate(nextProps, nextState) {}
  // componentWillUpdate(nextProps, nextState) {}
  // componentDidUpdate(nextProps, nextState) {}
  // componentWillUnmount() {}

  shouldComponentUpdate(nextProps, nextState) {
    return nextProps.requestsInProgress !== this.props.requestsInProgress;
  }

  render() {
    const { requestsInProgress } = this.props;
    return (
      requestsInProgress ? (
        <div css={styles.base}>
          <div />
          <div />
        </div>
      ) : null
    );
  }
}


RequestsInProgressBar.propTypes = {
  requestsInProgress: PropTypes.bool
};

RequestsInProgressBar.contextTypes = {
};

const mapStateToProps = (state, ownProps) => {
  return {
    requestsInProgress: state.shared.requestsInProgress
  };
};

export default connect(mapStateToProps)(RequestsInProgressBar);
