// @ts-nocheck

import _ from "lodash";
import PropTypes from "prop-types";
import React, { Component, PureComponent, Fragment } from "react";


import { connect } from "react-redux";

import { bindActionCreators } from "@reduxjs/toolkit";
import { Global, css } from "@emotion/react";
import autobind from "autobind-decorator";
import hoistNonReactStatics from "hoist-non-react-statics";

import { BREAKPOINTS, COLORS, FLEX, HELPERS, SPACES, MIN_HEIGHT, VARS, STYLES } from "styles-js";
import RouteAuthentication from "core/RouteAuthentication.tsx";
import QueryString from "core/QueryString.ts";

import RouteLoadingIndicator from "../components/RouteLoadingIndicator/RouteLoadingIndicator.tsx";

import { routeEnter, routeExit, sendErrorMessage } from "../containers/App/App.slice";

const RouteProvider = (TargetRouteComponent, routeItem) => {
  class StateProvider extends Component {

    constructor(props, context) {
      super(props, context);
    }

    componentDidMount() {
      this.props.appActions.routeEnter(this.createRouteObject());
      document.body.classList.add(`page-${routeItem.slug}`);
    }

    componentWillUnmount() {
      this.props.appActions.routeExit(this.createRouteObject());
      document.body.classList.remove(`page-${routeItem.slug}`);
    }

    @autobind
    createRouteObject(props) {
      // console.log("routeItem ", routeItem);
      const {
        location,
        match
      } = this.props;
      const routeObject = Object.assign({},
        location,
        _.omit(routeItem, "component"),
        {
          params: _.get(match, "params"),
          query: QueryString.parse(location.search, { ignoreQueryPrefix: true })
        }
      );
      // console.log("routeObject ", routeObject);
      return routeObject;
    }

    @autobind
    createRouteProps() {
      // console.log("this.props ", this.props);
      const routeProps = Object.assign(
        {},
        _.omit(this.props, [
          "appState",
          "appActions"
        ]),
        {
          route: {
            ..._.omit(routeItem, "component"),
            params: _.get(this.props, "match.params", {}),
            query: QueryString.parse(_.get(this.props, "location.search", ""), { ignoreQueryPrefix: true }),
            search: _.get(this.props, "location.search", "")
          },
          routeParams: _.get(this.props, "match.params", {}),
          state: {}
        }
      );
      // console.log("routeProps ", routeProps);
      return routeProps;
    }

    render() {
      const {
        hasUserLoggedIn,
        memberDetails,
        initInProgress
      } = this.props.appState;
      const routeObject = this.createRouteObject();
      // console.log("LOG: StateProvider -> render -> routeObject", routeObject);
      // console.log("LOG: StateProvider -> render -> this.props", this.props);
      return (
        <>
          {routeObject?.hideFooterOnMobile && (
            <Global styles={css`
              ${HELPERS.media.smallerThan.sm} {
                #footer {
                  display: none;
                }
              }
            `} />
          )}

          <RouteLoadingIndicator isActive={initInProgress} />

          {!initInProgress && (
            <RouteAuthentication
              sendErrorMessage={this.props.appActions.sendErrorMessage}
              initInProgress={initInProgress}
              hasUserLoggedIn={hasUserLoggedIn}
              activeRoute={routeObject}>

              {React.createElement(
                TargetRouteComponent,
                this.createRouteProps()
              )}

            </RouteAuthentication>
          )}

        </>
      );

    }
  }

  StateProvider.propTypes = {
    appState: PropTypes.object.isRequired,
    appActions: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    location: PropTypes.object
  };

  StateProvider.contextTypes = {
    store: PropTypes.object.isRequired
  };

  const mapStateToProps = (state) => {
    return {
      appState: state.app
    };
  };

  const mapDispatchToProps = (dispatch) => ({
    appActions: bindActionCreators(
      {
        routeEnter,
        routeExit,
        sendErrorMessage
      },
      dispatch
    )
  });

  hoistNonReactStatics(StateProvider, TargetRouteComponent);

  return connect(mapStateToProps, mapDispatchToProps)(StateProvider);
};


export default RouteProvider;
