// @ts-nocheck
import _ from "lodash";
import PropTypes from "prop-types";
import React, { Component, PureComponent, Fragment } from "react";

import { connect } from "react-redux";

import { bindActionCreators, compose } from "@reduxjs/toolkit";
import { Global } from "@emotion/react";
import Modal from "semantic-ui-react/dist/commonjs/modules/Modal";

import { Button, Icon } from "kit/ui";


import { confirmActions } from "./Confirm.slice";

import * as styles from "./Confirm.styles";

import closeModal from "../../assets/svg/close-modal.svg";



class Confirm extends Component {

  static getDerivedStateFromProps({ active: isActive }, { mustShow }) {
    if (!_.isNil(isActive) && isActive !== mustShow) { // eslint-disable-line eqeqeq
      return { mustShow: isActive };
    }
    return { mustShow };
  }

  constructor(props, context) {
    super(props, context);
  }

  state = {
    mustShow: false
  };

  handleYes = () => {
    this.props.actions.yes(this.props.id);
    this.setState({
      mustShow: false
    });
  };

  handleNo = () => {
    this.props.actions.no(this.props.id);
    this.setState({
      mustShow: false
    });
  };

  handleCancel = () => {
    this.props.actions.cancel(this.props.id);
    this.setState({
      mustShow: false
    });
  };

  // UNSAFE_componentWillMount() {}
  // componentDidMount() {}
  // UNSAFE_componentWillReceiveProps(nextProps, nextContext) {}
  // shouldComponentUpdate(nextProps, nextState, nextContext) {}
  // componentWillUpdate(nextProps, nextState, nextContext) {}
  // componentDidUpdate(prevProps, prevState, prevContext)
  // componentWillUnmount() {}

  iconsBySubType = {
    success: "check",
    error: "error",
    warning: "warning",
    info: "info"
  };
  titlesBySubType = {
    success: "Exito",
    error: "Attencione",
    info: "Aviso",
    warning: "Aviso"
  };

  render() {
    const {
      id,
      active,
      buttonNo,
      buttonYes,
      subType,
      title,
      icon,
      content,
      description,
      showButtonNo,
      iconProps,
      titleProps,
      contentProps,
      actionsProps,
      descriptionProps,
      showCloseIcon = false
    } = this.props;
    // console.log("this.props ", this.props);
    const { mustShow } = this.state;
    // console.log("this.state ", this.state);
    // console.log("subType ", subType);
    const iconName = (icon || this.iconsBySubType[subType]) && `myk ${icon || this.iconsBySubType[subType]}`;
    // console.log("iconName ", iconName);
    return (
      <>
        <Global
          styles={styles.globalStyles} />
        <Modal
          id={`confirm-${id}`}
          size="small"
          closeOnDocumentClick={false}
          closeOnDimmerClick={false}
          closeOnEscape={false}
          closeIcon={showCloseIcon &&
            <img src={closeModal} css={styles.closeIcon} alt="Close Modal Icon" />}
          open={mustShow}
          onClose={this.handleCancel}
          css={[
            styles.base,
            subType && styles.subTypes[subType]
          ]}>
          <Modal.Content>
            {!!iconName && (
              <div
                className="confirm-icon"
                css={styles.icon}
                {...iconProps}>
                <Icon name={iconName} />
              </div>
            )}
            <div
              className="confirm-title"
              css={styles.title}
              {...titleProps}
              dangerouslySetInnerHTML={{
                __html: title
              }} />
            {!!content && (
              <div
                className="confirm-content"
                css={styles.content}
                {...contentProps}
                dangerouslySetInnerHTML={{
                  __html: content
                }} />
            )}
            {!!description && (
              <div
                className="confirm-desc"
                css={styles.description}
                {...descriptionProps}
                dangerouslySetInnerHTML={{
                  __html: description
                }} />
            )}
            <div
              className="confirm-buttons"
              css={styles.actions}
              {...actionsProps}>
              {showButtonNo && (
                <Button
                  className="confirm-button-no"
                  fluid
                  size="small"
                  outline
                  css={styles.buttonNo}
                  onClick={this.handleNo}>
                  {_.defaultTo(buttonNo, "No")}
                </Button>
              )}
              <Button
                className="confirm-button-yes"
                outline={!showButtonNo}
                fluid={showButtonNo}
                theme={showButtonNo ? "orange" : "primary"}
                size="small"
                css={[
                  !buttonYes && {
                    paddingRight: 70,
                    paddingLeft: 70
                  },
                  styles.buttonYes
                ]}
                onClick={this.handleYes}>
                {_.defaultTo(buttonYes, "Si")}
              </Button>
            </div>
          </Modal.Content>
        </Modal>
      </>
    );
  }
}

Confirm.propTypes = {
  actions: PropTypes.object.isRequired,
  actionsProps: PropTypes.any,
  active: PropTypes.any,
  buttonNo: PropTypes.any,
  buttonYes: PropTypes.any,
  content: PropTypes.any,
  contentProps: PropTypes.any,
  description: PropTypes.any,
  descriptionProps: PropTypes.any,
  icon: PropTypes.any,
  iconProps: PropTypes.any,
  id: PropTypes.any,
  showButtonNo: PropTypes.any,
  state: PropTypes.shape({
    id: PropTypes.any
  }),
  subType: PropTypes.any,
  title: PropTypes.any,
  titleProps: PropTypes.any,
  showCloseIcon: PropTypes.bool
};

const mapStateToProps = (state) => {
  return state.confirm;
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(confirmActions, dispatch),
  dispatch
});

export default connect(mapStateToProps, mapDispatchToProps)(Confirm);

