// @ts-nocheck


import _ from "lodash";
import React, { Component, PureComponent, Fragment } from "react";

import delay from "@redux-saga/delay-p";
import { push, LOCATION_CHANGE } from "connected-react-router";
import ms from "ms";
import { take, call, put, fork, all, cancel } from "redux-saga/effects";
import uuid from "uuid/v4";


import RequestorConfig from "core/RequestorConfig";
import { Info, Progress, Warning } from "kit/alert";

import ENV from "../constants/ENV";

import { RequestorInstance as RequestorInstanceApi } from "../core/RequestHandler";
import ErrorLoggerService from "../core/ErrorLoggerService";


const Requestor = new RequestorConfig(window.location.origin);
const RequestorInstance = Requestor.returnInstance();

const appInitDelay = ms("10sec");
const delayBetweenVersionCheck = ms("1min");


function* versionCheckSaga(localVersion: string = ENV.SOURCE_BUILD_NUMBER) {
  try {
    const version = yield call(getVersion);
    console.info("version", version);
    const versionsExistAndDifferent = version && localVersion && (version !== localVersion);
    if (versionsExistAndDifferent) {
      try {
        window.location.reload();
      }
      catch (error) {
        console.error("ERROR FROM VERSION CHECK:", error);
      }
    }
    else {
      yield call(delay, delayBetweenVersionCheck);
      yield call(versionCheckSaga);
    }
  }
  catch (err) {
    ErrorLoggerService("APP", "versionCheckSaga", err);
  }
}


function* getVersion() {
  try {
    const req = yield call(RequestorInstance, {
      url: `/version.json?v=${uuid()}`
    });
    return _.get(req, "data.version");
  }
  catch (err) {
    ErrorLoggerService("APP", "versionCheckSaga", err);
  }
}



export default function* sagaWatcher() {
  if (import.meta.env.PROD) {
    yield call(delay, appInitDelay);
    yield fork(versionCheckSaga);
  }
}
