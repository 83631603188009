// @ts-nocheck

import _ from "lodash";
import React, { Component, PureComponent, Fragment } from "react";
import { render, unmountComponentAtNode } from "react-dom";

import App from "./App";
import routesObject from "./routesObject";
import routesMapped from "./routesMapped";
import ThirdParty from "./core/ThirdParty";

ThirdParty.FullStory.init();
routesMapped.init(routesObject);


const MOUNT_NODE = document.getElementById("root");


const renderApp = () =>
  render(
    <App />,
    MOUNT_NODE
  );
renderApp();
