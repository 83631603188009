// @ts-nocheck
import _ from "lodash";
import React, { Component, PureComponent, Fragment } from "react";

import { Switch } from "react-router-dom";

import MainLayout from "./MainLayout";
import { returnRouteElement } from "./routeHelpers";
import routesMapped from "./routesMapped";
import ErrorBoundary from "./App.ErrorBoundary";



//
// ─── ROUTE DEFINITIONS ──────────────────────────────────────────────────────────
//
class Routes extends Component {

  constructor(props: Object, context: Object) {
    super(props, context);
  }

  routesMappedArray = routesMapped.returnRoutes();

  allRoutes = _.map(this.routesMappedArray, obj => returnRouteElement(obj));

  render() {
    return (
      <MainLayout>
        <ErrorBoundary>
          <Switch>

            {this.allRoutes}

          </Switch>
        </ErrorBoundary>
      </MainLayout>
    );
  }

}


export default Routes;
