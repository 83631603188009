// @ts-nocheck
import _ from "lodash";
import PropTypes from "prop-types";
import React, { Component, PureComponent, Fragment } from "react";


class ErrorBoundary extends React.Component {

  static getDerivedStateFromError(error) {
    return { hasUiCrashed: error };
  }

  state = { hasUiCrashed: false };

  componentDidCatch(err, info) {
    ErrorLoggerService("App.ErrorBoundary", "componentDidCatch err ", err);
    ErrorLoggerService("App.ErrorBoundary", "componentDidCatch info ", info);
  }

  render() {
    const { hasUiCrashed } = this.state;
    if (hasUiCrashed) {
      // You can render any custom fallback UI
      return (
        <div style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          textAlign: "center",
          padding: 20
        }}>
          <br />
          <h1>&iexcl;Vaya!</h1>
          <h5>Algo sali&oacute; mal.<br /><br />Vuelva a cargar la p&aacute;gina e int&eacute;ntelo de nuevo.</h5>
          <br />
          <button
            style={{
              background: "none",
              backgroundColor: "transparent",
              border: "solid 1px transparent",
              borderColor: "#4251CD",
              borderRadius: 28,
              boxShadow: "none",
              color: "#4251CD",
              cursor: "pointer",
              fontSize: 18,
              fontStyle: "normal",
              height: 55,
              lineHeight: 35,
              margin: 0,
              outline: 0,
              padding: "10px 20px",
              textAlign: "center",
              display: "flex",
              textShadow: "none",
              alignItems: "center",
              justifyContent: "center"
            }}
            onClick={() => window.location.reload(true)}
            type="button">
            Int&eacute;ntelo de nuevo
          </button>
          <br />

        </div>
      );
    }

    return this.props.children;
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.node.isRequired
};


export default ErrorBoundary;
