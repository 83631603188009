import { css } from "@emotion/react";

import { BREAKPOINTS, COLORS, FLEX, HELPERS, SPACES, MIN_HEIGHT, VARS, STYLES } from "styles-js";


export const wrapper = css`
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  ${HELPERS.media.smallerThan.sm} {
    min-height: 100vh !important;
    height: auto !important;
  }
`;


export const content = css`
  flex: 1 1 auto;
  margin: 0;
  padding: 0;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: stretch;
  > * {
    opacity: 1 !important;
  }
  &:after {
    display: none !important;
  }
`;


export const main = css`
  margin: 0;
  padding: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  flex: 1 1 auto;
  ${HELPERS.media.smallerThan.sm} {
    @media (max-height: 800px) {
      min-height: 300px !important;
      min-height: 50vh !important;
    }
  }
`;
