// @ts-nocheck
import _ from "lodash";

import { Validation } from "kit/helpers";
import DateService from "core/DateService";
import ThirdParty from "core/ThirdParty";

import languageForms from "../language/language.forms";

import "../core/ErrorLoggerService";



import "dayjs/locale/es";
DateService.setLocale("es");

// remove no-js class
document.documentElement.classList.remove("no-js");


// enable service workers
async function initializeServiceWorker() {


  if (
    window.location.protocol === "https:" &&
    "serviceWorker" in navigator &&
    import.meta.env.MODE === "production"
  ) {

    navigator.serviceWorker.register("/service-worker.js");

  }

}


export default (dispatchFn: Function) => {

  ThirdParty.WebPush.init();
  // ThirdParty.YandexMetrica.init();

  initializeServiceWorker();

  // set validation error messages
  Validation.setSuccessReturnValue(undefined);

  Validation.setLanguageObject(languageForms);

};
