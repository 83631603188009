// @ts-nocheck
import PropTypes from "prop-types";
import React, { Component, PureComponent, memo } from "react";
import _ from "lodash";

import { push } from "connected-react-router";
import { Link } from "react-router-dom";
import isEqual from "react-fast-compare";


import { Popup } from "semantic-ui-react";

import Storage from "core/Storage";
import { MemberDto, MemberDetailsStateType } from "@myk";
import { Icon, DateFormat, Button } from "kit/ui";
import { BREAKPOINTS, COLORS, FLEX, HELPERS, SPACES, MIN_HEIGHT, VARS, STYLES } from "styles-js";
import { AuthReset } from "core/Auth";
import { Container } from "kit/grid";

import MemberContext from "../../contexts/MemberContext";

import STORAGE from "../../constants/STORAGE";
import ROUTES_BASED_ON_CUSTOMER_STATUS from "../../constants/ROUTES_BASED_ON_CUSTOMER_STATUS";
import CUSTOMER_STATUSES from "../../constants/CUSTOMER_STATUSES";

import Loader from "../../kit/ui/Button/Button.Loader";
import routesObject from "../../routesObject";

import * as styles from "./Header.styles";

import logo from "../../assets/img/mykredit-es.svg";



type ComponentPropsTypes = {}



// eslint-disable-next-line react/no-multi-comp
class Header extends Component<ComponentPropsTypes> {

  shouldComponentUpdate(nextProps, nextState, nextContext) {
    return !(isEqual(this.props, nextProps) && isEqual(this.state.headerVisible, nextState.headerVisible));
  }

  routesBasedOnCustomerStatus = ROUTES_BASED_ON_CUSTOMER_STATUS();

  state = {
    prevScroll: undefined,
    headerVisible: true
  };

  scrollListener = _.throttle((event: Event) => {
    const {
      prevScroll
    } = this.state;
    const isDirectionDown = window.scrollY > this.state.prevScroll;
    const headerVisible = window.scrollY < VARS.headerHeight || !isDirectionDown || !prevScroll || (prevScroll && !isDirectionDown);
    this.setState({
      prevScroll: scrollY,
      headerVisible
    });
  }, 50, {
    leading: true,
    trailing: true
  });

  attach = () =>
    window.addEventListener("scroll", this.scrollListener);

  detach = () =>
    window.removeEventListener("scroll", this.scrollListener);

  componentDidMount = this.attach;

  componentWillUnmount = this.detach;

  toggleMobileMenu = () => this.props.handleToggleMobileMenuVisibility(!this.props.isMobileMenuVisible);

  render() {
    const {
      handleUserLogout,
      isMobileMenuVisible,
      initInProgress
    } = this.props;
    return (
      <div
        ref={this.headerRef}
        id="header" css={[
          styles.base,
          {
            height: `${VARS.headerHeight}px`
          }
        ]}>
        <div css={[
          styles.wrapper,
          !this.state.headerVisible && styles.hidden
        ]}>

          <Container>

            <div css={styles.inner}>

              <div css={styles.logoHolder}>
                <Link to="/">
                  <img css={styles.logo} src={logo} alt="Mykredit Logo" />
                </Link>
              </div>

              <div css={styles.navHolder}>
                <ul>
                  <li><Link to={routesObject.howItWorks.url}>Cómo funciona</Link></li>
                  <li><Link to={routesObject.personalLoans.url}>Préstamos personales</Link></li>
                  <li><Link to={routesObject.faq.url}> Ayuda </Link></li>
                  <li><Link to={routesObject.aboutUs.url}>Sobre myKredit</Link></li>
                  <li><Link to={routesObject.contactUs.url}>Contacto</Link></li>
                  <li><a rel="noreferrer" href="/blog">Blog</a></li>
                  <MemberContext.Consumer>
                    {
                      (member: MemberDetailsStateType) =>
                        !!member && (
                          <li><Link className="accountLink" to={routesObject.account.url}>Gestión de la Cuenta</Link></li>
                        )
                    }
                  </MemberContext.Consumer>
                </ul>
              </div>

              <MemberContext.Consumer>
                {(member: MemberDetailsStateType) => (
                  <>
                    {initInProgress && (
                      <a css={styles.loginBtn}>
                        <Loader loading style={styles.loadingStyles} />
                      </a>
                    )}
                    {!initInProgress && (
                      <>
                        <Link
                          to={member ? Storage.Session.get(STORAGE.loanFlowIdFromLoanApply + member.id) ? routesObject.loanApply.url : this.routesBasedOnCustomerStatus[member.status] : routesObject.login.url}
                          css={[
                            styles.loginBtn,
                            !!member && styles.userLoggedInName
                          ]}>
                          <>
                            <Icon size="20" name="myk user" />
                            {member ?
                              (
                                <>
                                  <span className="txt short">{member.twoLetterName}</span>
                                  <span className="txt full">{member.fullName}</span>
                                </>
                              ) :
                              (
                                <span className="txt">Área de Clientes</span>
                              )}
                          </>
                        </Link>

                        {!!member && (
                          <button
                            onClick={() => handleUserLogout()}
                            css={styles.logoutBtn}>
                            <Icon name="myk logout" />
                          </button>
                        )}

                      </>

                    )}

                  </>
                )}
              </MemberContext.Consumer>


              <div css={styles.mobileTogglerHolder}>
                <button
                  onClick={this.toggleMobileMenu}
                  css={[
                    styles.toggler,
                    isMobileMenuVisible && styles.mobileMenuActive
                  ]}
                  type="button"
                  title="Mobile Menu Toggler">
                  <span css={styles.togglerBox}>
                    <span css={styles.togglerInner} />
                  </span>
                </button>
              </div>

            </div>
          </Container>
        </div>

      </div>
    );

  }
}


Header.propTypes = {
  isMobileMenuVisible: PropTypes.bool,
  initInProgress: PropTypes.bool,
  handleToggleMobileMenuVisibility: PropTypes.func.isRequired,
  handleUserLogout: PropTypes.func.isRequired
};

Header.contextTypes = {};


export default Header;
