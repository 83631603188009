// @ts-nocheck

import _ from "lodash";

import { take, call, put, fork, all, takeEvery, race, spawn, cancel, select } from "redux-saga/effects";
import delay from "@redux-saga/delay-p";
import { REHYDRATE } from "redux-persist/constants";

import { AuthGet, AuthReset } from "core/Auth";
import { Copy } from "kit/ui";

import { appActions } from "../containers/App/App.slice";
import { getCustomerPayloadWithToken, checkCampaignParameters } from "../containers/Shared/Shared.saga";
import { handleUserLogin } from "../containers/App/App.saga";


export function* initSaga() {
  yield put(appActions.initStart());
  yield race({
    rehydrate: take(REHYDRATE),
    delayer: call(delay, 1000)
  });
  try {

    const { token: tokenFromStore } = AuthGet();

    const queryJwt = yield select(state =>
      _.get(state.router, "location.query.jwt")
    );

    const token = queryJwt || tokenFromStore;

    if (token) {
      const { memberDetails } = yield call(getCustomerPayloadWithToken, token);
      if (memberDetails) {
        yield spawn(handleUserLogin, {
          token,
          memberDetails
        });
        yield take(appActions.authUserLogin);
        if (queryJwt) {
          Copy.copyStr(`?jwt=${queryJwt}`);
        }
      }
      else {
        yield put(appActions.authUserLogout());
      }
    }
    yield put(appActions.initFinish());

  }
  catch (err) {
    yield call(AuthReset);
    yield put(appActions.initFinish());
    ErrorLoggerService("APP", "appInitSaga", err); // eslint-disable-line dot-notation
  }
  finally {
    yield fork(checkCampaignParameters);
  }
}


export default function* sagaWatcher() {
  yield fork(initSaga);
}
