// @ts-nocheck
import _ from "lodash";
import React, { Component, PureComponent, Fragment } from "react";

import { Route } from "react-router-dom";

import { RouteObjectType } from "@myk";
import RouteProvider from "core/RouteProvider.tsx";


export const defaultRouteObject: RouteObjectType = {
  key: "",
  home: false,
  title: "",
  headTitle: "",
  navTitle: "",
  meta: {
    description: "",
    keywords: ""
  },
  authTypes: [],
  nav: []
};


const mergeRouteProps = (routeProps: RouteObjectType, extendedProps = {}) =>
  _.omitBy(
    {
      ...defaultRouteObject,
      key: routeProps.key,
      name: routeProps.name,
      path: routeProps.url,
      ...routeProps,
      authTypes: routeProps.authTypes,
      authTypesForbidden: routeProps.authTypesForbidden,
      nav: routeProps.nav,
      ...extendedProps
    },
    _.isNil
  );


//
// ─── ROUTE FUNCTIONS ────────────────────────────────────────────────────────────
//
export const returnRouteElement = (routeProps: RouteObjectType, extendedProps) => {

  const mergedProps = mergeRouteProps(routeProps, extendedProps);

  return <Route
    key={routeProps.key}
    exact
    path={routeProps.url}
    component={RouteProvider(routeProps.component, mergedProps)} />;
};


