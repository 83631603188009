// @ts-nocheck

import _ from "lodash";
import PropTypes from "prop-types";
import React, { Component, PureComponent, Fragment } from "react";
import { createPortal } from "react-dom";

import { connect } from "react-redux";

import { bindActionCreators } from "@reduxjs/toolkit";
import { Helmet } from "react-helmet";

import { Alert } from "kit/alert";
import { DateFormat } from "kit/ui";

import MemberContext from "./contexts/MemberContext";
import LoanJourneyContext from "./contexts/LoanJourneyContext";


import COUNTRY from "./constants/COUNTRY";

import Confirm from "./containers/Confirm/Confirm.container";


import Footer from "./components/Footer/Footer.component.lazy";
import Header from "./components/Header/Header.component";
import MobileMenu from "./components/MobileMenu/MobileMenu.component";
import RequestsInProgressBar from "./components/RequestsInProgressBar/RequestsInProgressBar.component";
import UpdatePasswordModal from "./components/UpdatePasswordModal/UpdatePasswordModal.component";
import CookieModal from "./components/CookieModal/CookieModal.component";

import { confirmActions } from "./containers/Confirm/Confirm.slice";
import { appActions } from "./containers/App/App.slice";

import * as styles from "./MainLayout.styles";


// type ComponentPropsType = {
//   actions: Object & appActions.ActionsPropTypes;
//   state: Object & {
//     app: appActions.StatePropTypes,
//     shared: StatePropTypes;
//   };
// };


class MainLayout extends Component {

  constructor(props, context) {
    super(props, context);
  }

  state = {};

  // UNSAFE_componentWillMount() {}
  // componentDidMount() {}
  // UNSAFE_componentWillReceiveProps(nextProps, nextContext) {}
  // shouldComponentUpdate(nextProps, nextState, nextContext) {}
  // componentWillUpdate(nextProps, nextState, nextContext) {}
  // componentDidUpdate(prevProps, prevState, prevContext)

  static propTypes = {
    children: PropTypes.object.isRequired,
    state: PropTypes.object.isRequired,
    actions: PropTypes.object.isRequired
  };

  static contextTypes = {
    store: PropTypes.object.isRequired
  };

  static childContextTypes = {
    customerStatus: PropTypes.string,
    customerId: PropTypes.number,
    customer: PropTypes.object,
    country: PropTypes.object,
    mainElementRef: PropTypes.any,
    confirm: PropTypes.object.isRequired,
    modal: PropTypes.object.isRequired
  };

  getChildContext() {
    const { state, actions } = this.props;
    return {
      customerStatus: _.get(state.app, "memberDetails.status"),
      customerId: _.get(state.app, "memberDetails.id"),
      customer: _.get(state.app, "memberDetails"),
      country: COUNTRY,
      mainElementRef: this.state.mainElementRef,
      confirm: {
        show: actions.showConfirm
      },
      modal: {
        state: _.get(state.app, "modalState", {}),
        open: id => actions.toggleModalState(id, true),
        close: id => actions.toggleModalState(id, false)
      }
    };
  }

  componentDidMount() {
    // try {
    //   const main = document.getElementById("main");
    //   /* eslint-disable no-undef */
    //   new ResizeObserver(
    //     _.throttle(() => {
    //       main.dispatchEvent(new Event("scroll"));
    //     }, 5, {
    //       leading: true,
    //       trailing: true
    //     })
    //   )
    //     .observe(main);
    // }
    // catch (err) {
    //
    // }
  }

  createMainElementRef = mainElementRef => this.setState({ mainElementRef });

  render() {
    const {
      actions,
      state,
      children
    } = this.props;
    const {
      app,
      shared,
      dashboard
    } = state;
    return (
      <div
        id="wrapper"
        css={styles.wrapper}>

        <RequestsInProgressBar />

        <Helmet titleTemplate="myKredit | %s">
          {app.isMobileMenuVisible && <body className="overflow-hidden" />}
        </Helmet>
        <MemberContext.Provider value={app.memberDetails}>
          <>
            <MobileMenu
              handleToggleMobileMenuVisibility={actions.toggleMobileMenuVisibility}
              isMobileMenuVisible={app.isMobileMenuVisible}
              handleUserLogout={actions.authUserLogout}
              hasActiveLoan={app.hasActiveLoan} />
            <Header
              initInProgress={app.initInProgress}
              isMobileMenuVisible={app.isMobileMenuVisible}
              handleToggleMobileMenuVisibility={actions.toggleMobileMenuVisibility}
              handleUserLogout={actions.authUserLogout} />

            <div id="content" css={[
              styles.content,
              app.initInProgress && {
                minHeight: "100vh"
              }
            ]}>
              <main
                id="main"
                css={styles.main}
                ref={this.createMainElementRef}>
                {children}
                <div id="main-portal-container" />
              </main>
            </div>
          </>
        </MemberContext.Provider>


        {!app.initInProgress && (
          <Footer />
        )}

        {app.hasUserLoggedIn && <UpdatePasswordModal />}
        <CookieModal approveCookiePermissions={actions.applyCookiePermissions} />

        {createPortal(<Confirm />, document.body)}

        {createPortal(<Alert />, document.body)}

      </div>
    );
  }

}


const mapStateToProps = (state) => {
  return {
    state: {
      app: state.app,
      shared: state.shared,
      dashboard: state.dashboard
    }
  };
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({
    ...appActions,
    showConfirm: confirmActions.show
  }, dispatch),
  dispatch
});

export default connect(mapStateToProps, mapDispatchToProps)(MainLayout);
