// @ts-nocheck

import _ from "lodash";

// import { delay } from 'redux-saga';
import { replace } from "connected-react-router";
import { call, put, take, takeEvery, takeLatest, fork, select, race } from "redux-saga/effects";

import { AuthReset } from "core/Auth";
import RequestorConfig from "core/RequestorConfig";
import QueryString from "core/QueryString";
import { Success, Danger, Info, Warning, Progress, Clear } from "kit/alert";
import { BREAKPOINTS, COLORS, FLEX, HELPERS, SPACES, MIN_HEIGHT, VARS, STYLES } from "styles-js";
import ThirdParty from "core/ThirdParty";

import languageCommon from "../language/language.common.js";

import { appActions } from "../containers/App/App.slice";
import { getRoute } from "../containers/App/App.saga";
import { toggleRequestsInProgressStatus } from "../containers/Shared/Shared.slice";
import { confirmActions } from "../containers/Confirm/Confirm.slice";
import { showConfirmDialogAndWaitForAction } from "../containers/Confirm/Confirm.saga";


import routesObject from "../routesObject";


function* handleRequestProgressBar() {
  let reqCount = 0;
  let resCount = 0;
  yield takeEvery(RequestorConfig.CONSTANTS.REQUEST_SENT, function* () {
    reqCount++;
    if (reqCount !== resCount) {
      yield put(toggleRequestsInProgressStatus(true));
    }
  });
  yield takeEvery(RequestorConfig.CONSTANTS.RESPONSE_RECEIVED, function* () {
    resCount++;
    if (reqCount === resCount) {
      yield put(toggleRequestsInProgressStatus(false));
    }
  });
}



function* handleResponseErrors({ payload }) {
  try {
    if (_.get(payload, "config.ignoreErrors")) {
      return true;
    }
    const res = _.pick(payload, [
      "data",
      "status",
      "cancelled"
    ]);

    if (res.cancelled) {
      const confirmResponse = yield call(showConfirmDialogAndWaitForAction, {
        subType: "error",
        title: "Aviso",
        content: "&iquest;Est&aacute;s sin conexi&oacute;n?",
        description: "Comprueba tu conexión a Internet y vuelve a intentarlo.",
        showButtonNo: true,
        buttonNo: "Recargar"
      });
      ThirdParty.FullStory.event("Timeout", {
        api,
        code: "Timeout",
        title: "Timeout",
        message: "Timeout"
      });

      if (confirmResponse.no) {
        window.location.reload(true);
      }
      return;
    }


    const errors = _.get(res, "data.errors");
    const api = _.get(payload, "config.url");
    const code = _.get(errors, "[0].code.name");
    const title = _.get(errors, "[0].title");
    const message = _.get(errors, "[0].message");


    switch (res.status) {
      case 401:
        yield call(AuthReset);
        const activeRoute = yield call(getRoute);
        yield put(
          replace({
            pathname: routesObject.login.url,
            search: QueryString.stringify({
              redirectTo: encodeURIComponent(activeRoute.pathname + activeRoute.search)
            })
          })
        );
        yield put(appActions.authUserLogout());
        ThirdParty.FullStory.event("Unauthorized", {
          api,
          code,
          title,
          message
        });
        yield put(confirmActions.show({
          icon: "info",
          title: "Su sesi&oacute;n ha caducado.",
          content: "Por favor ingrese nuevamente."
        }));
        break;

      case undefined:
      case 0:
      case -1:
        ThirdParty.FullStory.event("Network Error", {
          api,
          code: "Network Error",
          title: "Network Error",
          message: "Network Error"
        });
        const confirmRes = yield call(showConfirmDialogAndWaitForAction, {
          subType: "error",
          title: "Aviso",
          content: "&iquest;Est&aacute;s sin conexi&oacute;n?",
          description: "Su solicitud ha expirado. Comprueba tu conexi&oacute;n a Internet y vuelve a intentarlo.",
          buttonNo: "Recargar"
        });
        if (confirmRes.no) {
          window.location.reload(true);
        }
        break;

      case 404:
        yield call(showConfirmDialogAndWaitForAction, {
          subType: "warning",
          title: "404",
          content: "No conseguimos encontrar lo que est&aacute;s buscando. Ir a la p&aacute;gina de inicio."
        });
        ThirdParty.FullStory.event("Not Found", {
          api,
          code,
          title,
          message
        });
        yield put(replace(routesObject.home.url));
        break;

      case 429:
        yield fork(showConfirmDialogAndWaitForAction, {
          subType: "error",
          title: "Se han realizado demasiados intentos",
          content: "Por favor, int&eacute;ntelo de nuevo m&aacute;s tarde."
        });
        ThirdParty.FullStory.event("Too Many Requests", {
          api,
          code,
          title,
          message
        });
        break;

      case 422:
        if (_.isEmpty(errors)) return;
        const hasIdorExepction = errors?.some(item => item.title === "InsecureDirectObjectReferenceException");
        if (hasIdorExepction) {
          yield put(appActions.authUserLogout());
          yield put(replace(routesObject.login.url));
        }
        _.forEach(errors, (err) => {
          ThirdParty.FullStory.event("Business Exception", {
            api,
            code: _.get(err, "code.name"),
            title: _.get(err, "title"),
            message: _.get(err, "message")
          });
        });
        break;

      case 500:
        ThirdParty.FullStory.event("Unhandled Exception", {
          api,
          code,
          title,
          message
        });
        const confirmRes2 = yield call(showConfirmDialogAndWaitForAction, {
          subType: "error",
          title: "Aviso",
          content: "Ha ocurrido un error. Vuelva a cargar la p&aacute;gina o int&eacute;ntelo de nuevo.",
          showButtonNo: true,
          buttonNo: "Recargar"
        });
        if (confirmRes2.no) {
          window.location.reload(true);
        }
        break;

      default:
        return true;

    }

  }
  catch (err) {
    ErrorLoggerService("APP", "errorHandlerSaga", err);
  }
}


export default function* errorHandlerSaga() {
  yield fork(handleRequestProgressBar);
  yield takeEvery(RequestorConfig.CONSTANTS.RESPONSE_RECEIVED, handleResponseErrors);
}
