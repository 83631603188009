// @ts-nocheck

import _ from "lodash";

import delay from "@redux-saga/delay-p";
import { fork, all, take, race, put, call, takeEvery } from "redux-saga/effects";
import { LOCATION_CHANGE } from "connected-react-router";

import { formActionSaga } from "core/FormSagaProvider";
import { watchDismissableAlerts } from "kit/alert";
// ? ### IMPORTED-SAGA ###
import Dengage from "core/Dengage";

import sharedSaga from "../containers/Shared/Shared.saga";
import { initFinish, routeEnter } from "../containers/App/App.slice";
import appSaga, { getMember } from "../containers/App/App.saga";
import confirmSaga from "../containers/Confirm/Confirm.saga";

// CORE
import versionCheckSaga from "./versionCheckSaga";
import clientLoggerSaga from "./clientLoggerSaga";
import appInitSaga from "./appInitSaga";
import browserEventsSaga from "./browserEventsSaga";
import errorHandlerSaga from "./errorHandlerSaga";


function* watchRoutesAndAppInit() {
  const [ initFinishAction, routeEnterAction ] = yield all([
    take(initFinish),
    take(routeEnter)
  ]);
  const memberDetails = yield call(getMember);
  Dengage.send(memberDetails.status, "sendDeviceEvent", "page_view_events", {
    // eslint-disable-next-line camelcase
    page_url: window.location.href
  });
}

function* sendDengagePageViewEvent({ type, payload }) {
  if (!payload.isFirstRendering) {
    const memberDetails = yield call(getMember);
    Dengage.send(memberDetails.status, "sendDeviceEvent", "page_view_events", {
      // eslint-disable-next-line camelcase
      page_url: window.location.href
    });
  }
}

export default function* rootSaga() {
  /**
     * TODO:	PRODUCTION REMOVE
     * -test purposes only
    **/
  // yield put({ type: APP.INIT_FINISH });
  // return;
  /**
      * TODO:	PRODUCTION REMOVE
     **/
  // eslint-disable-next-line no-unreachable
  yield all([
    fork(clientLoggerSaga),
    fork(versionCheckSaga),
    fork(sharedSaga),
    fork(confirmSaga),
    fork(watchRoutesAndAppInit),
    fork(watchDismissableAlerts),
    takeEvery(LOCATION_CHANGE, sendDengagePageViewEvent),
    // fork(browserEventsSaga),
    fork(formActionSaga),
    fork(errorHandlerSaga),
    fork(appInitSaga),
    fork(appSaga)
  ]);
}
