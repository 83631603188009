// @ts-nocheck
import React, { Component, PureComponent, Fragment } from "react";
import PropTypes from "prop-types";
import _ from "lodash";

import { css, Global } from "@emotion/react";
import { Link } from "react-router-dom";

import { type MemberDetailsStateType } from "@myk";
import { Container, Row, Col } from "kit/grid";
import { Button, Icon, Currency, DateFormat } from "kit/ui";
import { BREAKPOINTS, COLORS, FLEX, HELPERS, SPACES, MIN_HEIGHT, VARS, STYLES } from "styles-js";

import MemberContext from "../../contexts/MemberContext";

import CUSTOMER_STATUSES from "../../constants/CUSTOMER_STATUSES";
import COUNTRY from "../../constants/COUNTRY";

import email from "../../containers/StaticPages/assets/svg/email.svg";
import supportAgent from "../../containers/StaticPages/assets/svg/support-agent.svg";


import routesObject from "../../routesObject";

import * as styles from "./MobileMenu.styles.js";

import gift from "./assets/svg/gift.svg";

const REDIRECT_URL = {
  [CUSTOMER_STATUSES.new]: routesObject.otp.url,
  [CUSTOMER_STATUSES.preRegistered]: routesObject.addressDetails.url,
  [CUSTOMER_STATUSES.registered]: routesObject.loanRequest.url,
  [CUSTOMER_STATUSES.securityChecked]: routesObject.loanRequest.url,
  [CUSTOMER_STATUSES.systemApproved]: routesObject.loanRequest.url
};


class MobileMenu extends PureComponent {

  constructor(props, context) {
    super(props, context);
  }

  hide = () => this.props.handleToggleMobileMenuVisibility(false);
  show = () => this.props.handleToggleMobileMenuVisibility(true);

  // UNSAFE_componentWillMount() {}
  // componentDidMount() {}
  // UNSAFE_componentWillReceiveProps(nextProps) {}
  // shouldComponentUpdate(nextProps, nextState) {}
  // componentWillUpdate(nextProps, nextState) {}
  // componentDidUpdate(nextProps, nextState) {}
  // componentWillUnmount() {}

  render() {
    const { isMobileMenuVisible, handleUserLogout, hasActiveLoan } = this.props;
    return (
      <>
        {isMobileMenuVisible && (
          <Global styles={"body {height: 100vh}"} />
        )}
        <div css={[
          styles.base,
          isMobileMenuVisible && styles.menuVisible
        ]} style={{
          top: window.scrollY ? `${window.scrollY + VARS.headerHeight}px` : `${VARS.headerHeight}px`,
          height: `${window.innerHeight - VARS.headerHeight}px`
        }}>
          <MemberContext.Consumer>
            {(member: MemberDetailsStateType) => (
              <div css={[ styles.inner, !member && { background: "white" } ]}>
                { !member && (
                  <div css={styles.topLinks}>
                    <Row space="8">
                      <Col width={1 / 2}>
                        <Button
                          size="small"
                          as={Link}
                          to={routesObject.login.url}
                          theme="primary"
                          content="Inicia Sesión" />
                      </Col>
                      <Col width={1 / 2}>
                        <Button
                          size="small"
                          as={Link}
                          style={styles.creaUnaButton}
                          to={routesObject.register.url}
                          theme="primary"
                          outline
                          content="Crea una Cuenta" />
                      </Col>
                    </Row>
                  </div>
                )}
                {
                  hasActiveLoan === false && <MemberContext.Consumer>
                    {(memberDetails: MemberDetailsStateType) => !!memberDetails && (
                      [
                        CUSTOMER_STATUSES.securityChecked,
                        CUSTOMER_STATUSES.systemApproved,
                        CUSTOMER_STATUSES.new,
                        CUSTOMER_STATUSES.preRegistered,
                        CUSTOMER_STATUSES.registered
                      ].includes(memberDetails.status) &&
                      <div css={styles.customerCreditContainer}>
                        <img src={gift} alt="Gift Image" />
                        <div css={styles.customerCreditContent}>
                          {
                            [
                              CUSTOMER_STATUSES.new,
                              CUSTOMER_STATUSES.preRegistered,
                              CUSTOMER_STATUSES.registered
                            ].includes(memberDetails.status) ? <p> Recibe hasta  </p> : <p> TU LÍMITE</p>
                          }
                          <p>
                            <Currency value={[
                              CUSTOMER_STATUSES.new,
                              CUSTOMER_STATUSES.preRegistered,
                              CUSTOMER_STATUSES.registered
                            ].includes(memberDetails.status) ? 600 : memberDetails.customerCreditLimit} />
                          </p>
                        </div>
                        <Button
                          as={Link}
                          to={REDIRECT_URL[memberDetails.status]}
                          size="small"
                          content={
                            [
                              CUSTOMER_STATUSES.new,
                              CUSTOMER_STATUSES.preRegistered,
                              CUSTOMER_STATUSES.registered
                            ].includes(memberDetails.status) ? "¡Solicita YA!" : "¡Solicita Ahora!"
                          }
                          css={styles.creditButton} />
                      </div>
                    )}
                  </MemberContext.Consumer>
                }
                <div css={styles.top}>
                  <MemberContext.Consumer>
                    {(memberDetails: MemberDetailsStateType) => !!memberDetails && (
                      <div css={styles.firstLinks}>
                        <ul css={styles.topNav}>
                          <li><Link to={routesObject.dashboard.url}>Area de Clientes</Link></li>
                          <li><Link to={routesObject.creditHistory.url}>Historial de Préstamos</Link></li>
                          <li><Link to={routesObject.account.url}>Gestión de la Cuenta</Link></li>
                        </ul>
                      </div>
                    )}
                  </MemberContext.Consumer>
                  <div>
                    <ul css={[ styles.bottomNav ]}>
                      <li><Link to={routesObject.howItWorks.url}>Cómo funciona</Link></li>
                      <li> <Link to={routesObject.faq.url}>Ayuda</Link> </li>
                      <li><Link to={routesObject.aboutUs.url}>Sobre myKredit</Link></li>
                      <li><Link to={routesObject.contactUs.url}>Contacto</Link></li>
                    </ul>

                    {
                      !!member && (
                        <ul css={[
                          styles.bottomNav,
                          styles.logout
                        ]}>
                          <div css={styles.line} />
                          <li><a onClick={() => handleUserLogout()}>
                            Cerrar Sesión <Icon style={{ marginLeft: 10 }} name="myk logout" />
                          </a></li>
                        </ul>
                      )
                    }
                  </div>
                </div>
                <div>
                  <Row wrap="wrap" css={styles.contactWrapper}>
                    <Col css={styles.contactCard}>
                      <a href={`mailto:${COUNTRY.infoEmail}`} css={styles.contactCardWrapper}>
                        <img src={email} css={styles.contactCardIcon} alt="Email Icon" />
                        <div css={styles.contactCardContent}>
                          <h2> Enviar email </h2>
                          <p> {COUNTRY.infoEmail} </p>
                        </div>
                      </a>
                    </Col>
                    <Col css={styles.contactCard}>
                      <a href={`tel:${COUNTRY.phoneCustomerSupport}`} css={styles.contactCardWrapper}>
                        <img src={supportAgent} css={styles.contactCardIcon} alt="Support Agent Image" />
                        <div css={styles.contactCardContent}>
                          <h2> Llamanos </h2>
                          <p> {COUNTRY.phoneCustomerSupport} </p>
                        </div>
                      </a>
                    </Col>
                  </Row>
                </div>
              </div>)}
          </MemberContext.Consumer>
        </div>
        <div
          onClick={this.hide}
          css={[
            styles.backdrop,
            isMobileMenuVisible && styles.backDropVisible
          ]} />
      </>
    );
  }
}


MobileMenu.propTypes = {
  handleUserLogout: PropTypes.func.isRequired,
  handleToggleMobileMenuVisibility: PropTypes.func.isRequired,
  isMobileMenuVisible: PropTypes.bool,
  hasActiveLoan: PropTypes.bool
};

MobileMenu.contextTypes = {
};


export default MobileMenu;
