// @ts-nocheck
import React, { Component, PureComponent, Fragment } from "react";
import _ from "lodash";

import { Global } from "@emotion/react";

import * as styles from "./GlobalStyles.styles";


class GlobalStyles extends Component {
  shouldComponentUpdate = _.stubFalse;
  render() {
    return (
      <>
        <Global styles={styles.dimmer} />
        <Global styles={styles.modal} />
        <Global styles={styles.popup} />
        <Global styles={styles.placeholder} />
        <Global styles={styles.accordion} />
        <Global styles={styles.checkbox} />
      </>
    );
  }
}


export default GlobalStyles;
