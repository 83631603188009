// @ts-nocheck

import React, { Component, PureComponent, Fragment } from "react";
import PropTypes from "prop-types";
import _ from "lodash";

import { Link } from "react-router-dom";
import Dimmer from "semantic-ui-react/dist/commonjs/modules/Dimmer";

import { Container, Row, Col } from "kit/grid";
import QueryString from "core/QueryString";
import { Button, Icon, Copy, DateFormat, PageWrapper, Title } from "kit/ui";

import routesObject from "../../routesObject";


class AuthenticationError extends Component {

  constructor(props) {
    super(props);
  }

  // UNSAFE_componentWillMount() {}
  componentDidMount() {
    this.props.sendErrorMessage(this.props.customerStatus);
  }
  // UNSAFE_componentWillReceiveProps(nextProps) {}
  // shouldComponentUpdate(nextProps, nextState) {}
  // componentWillUpdate(nextProps, nextState) {}
  // componentDidUpdate(nextProps, nextState) {}
  // componentWillUnmount() {}

  render() {
    const { customerStatus, activeRoute } = this.props;

    return (
      <Dimmer inverted active>
        <Container>
          <Row justifyContent="center">
            <Col width={[ 1, 3 / 4 ]}>
              <h4 css={{
                color: "#222"
              }}>
                <Icon size={56} name="exclamation-triangle" />
              </h4>
              <h4 css={{
                color: "#222"
              }}>
                Para acceder a esta p&aacute;gina debes iniciar sesi&oacute;n.
              </h4>
              <br />
              {!customerStatus && (
                <Button
                  as={Link}
                  to={{
                    pathname: routesObject.login.url,
                    search: QueryString.stringify({
                      redirectTo: encodeURIComponent(activeRoute.pathname + activeRoute.search)
                    })
                  }}>
                  Inicia sesi&oacute;n
                </Button>
              )}
              {!!customerStatus && (
                <Button
                  as="a"
                  href="/">
                  Ir a la p&aacute;gina de inicio
                </Button>
              )}
            </Col>
          </Row>
        </Container>
      </Dimmer>
    );
  }
}


AuthenticationError.propTypes = {
  customerStatus: PropTypes.string,
  activeRoute: PropTypes.object,
  sendErrorMessage: PropTypes.func
};

AuthenticationError.contextTypes = {
};


export default AuthenticationError;
